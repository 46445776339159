import React, { useState } from "react";

const FilterModal = ({ type, isOpen, onClose, onApply }) => {
  const [filterValue, setFilterValue] = useState(
    type === "date"
      ? { from: null, to: null }
      : type === "speed"
      ? { min: null, max: null }
      : ""
  );

  const handleApply = () => {
    onApply(filterValue);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-40 p-10">
      <div
        className="fixed inset-0 bg-black opacity-50 z-40"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg shadow-lg w-96 p-5 z-50">
        <h3 className="text-lg font-semibold mb-4">
          {type === "date"
            ? "Filter by Date"
            : type === "speed"
            ? "Filter by Speed"
            : "Filter by Direction"}
        </h3>
        {type === "date" && (
          <div className="flex flex-col gap-4">
            <div>
              <label className="block text-sm font-medium">Start Date</label>
              <input
                type="date"
                className="mt-1 block w-full border rounded focus:outline-none h-8"
                value={filterValue.from || ""}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, from: e.target.value })
                }
              />
            </div>
            <div>
              <label className="block text-sm font-medium">End Date</label>
              <input
                type="date"
                className="mt-1 block w-full border rounded focus:outline-none h-8"
                value={filterValue.to || ""}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, to: e.target.value })
                }
              />
            </div>
          </div>
        )}
        {type === "speed" && (
          <div className="flex flex-col gap-4">
            <div>
              <label className="block text-sm font-medium">Min Speed</label>
              <input
                type="number"
                className="mt-1 block w-full border rounded focus:outline-none h-8"
                value={filterValue.min || ""}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, min: e.target.value })
                }
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Max Speed</label>
              <input
                type="number"
                className="mt-1 block w-full border rounded focus:outline-none h-8"
                value={filterValue.max || ""}
                onChange={(e) =>
                  setFilterValue({ ...filterValue, max: e.target.value })
                }
              />
            </div>
          </div>
        )}
        {type === "direction" && (
          <div>
            <label className="block text-sm font-medium">Direction</label>
            <select
              className="mt-1 block w-full border rounded focus:border-indigo-500 focus:ring-indigo-500 h-8"
              value={filterValue.direction || ""}
              onChange={(e) =>
                setFilterValue({ ...filterValue, direction: e.target.value })
              }
            >
              <option value="">Select Direction</option>
              <option value="Away">Away</option>
              <option value="Approach">Approach</option>
            </select>
          </div>
        )}
        <div className="flex justify-end gap-3 mt-5">
          <button
            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 px-4 py-2 text-white rounded hover:bg-blue-600"
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
